import React from "react";
import { Link, graphql } from "gatsby";
import Swiper from "react-id-swiper";
import Header from "../components/header";
import "../styles/styles.css";
var slugify = require("../helpers");

const Voorstellingen = ({ data, pageContext }) => {
  //console.log(data.allVoorstellingenResults.edges)
  //console.log(pageContext)
  const Slides = data.allMysqlVoorstellingen.edges.map((voorstelling) => (
    <div key={voorstelling.node.id}>
      <Link to={"/en/performance/" + slugify(voorstelling.node.title_en)}>
        <img
          src={
            "https://www.circusronaldo.be/uploads/" +
            voorstelling.node.mobile_image
          }
          alt=""
        />
      </Link>
    </div>
  ));
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <div>
      <Header back="true" backLink="/en" taal={pageContext.langKey} />
      <div>
        <h1>Performances</h1>
        <Swiper {...params}>{Slides}</Swiper>
      </div>
    </div>
  );
};
export default Voorstellingen;
export const query = graphql`
  query {
    allMysqlVoorstellingen {
      edges {
        node {
          id
          title_en
          mobile_image
        }
      }
    }
  }
`;
